<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'添加产品':$route.query.type == 2?"编辑产品":"编辑产品" }}</span>
    </div>
    <!-- 内容区 -->
    <div class="add">
      <div class="addleft">
        <div class="leftbox">
          <div
            class="onetxt"
            @click="goStep(1)"
            :class="step===1?'active':''"
          >1</div>
          <div
            class="base"
            @click="goStep(1)"
            :class="step===1?'active1':''"
          >基本设置</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(2)"
            :class="step===2?'active':''"
          >2</div>
          <div
            class="base"
            @click="goStep(2)"
            :class="step===2?'active1':''"
          >规格设置</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(3)"
            :class="step===3?'active':''"
          >3</div>
          <div
            class="base"
            @click="goStep(3)"
            :class="step===3?'active1':''"
          >其他设置</div>
          <!-- <div class="line"></div> -->
        </div>
      </div>
      <div class="addright">
        <!-- 第一步 -->
        <div
          class="one"
          v-if="step===1"
        >
          <div class="zengpinstep">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">基本设置</span>
            </h2>
            <a-form
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              labelAlign="left"
            >
              <!-- 来源 -->
              <a-form-item
                label="来源"
                class="mgb-18"
              >
                总部创建
              </a-form-item>
              <!-- 产品名称 -->
              <a-form-item
                label="产品名称"
                required
              >
                <!-- <span v-if="$route.query.type == 3">{{form.goods_name}}</span> -->
                <a-input
                  v-model="goods_name"
                  class="ant-input_1 wid-488"
                  :maxLength="20"
                  allowClear
                  placeholder="输入产品名称，20字以内"
                />
              </a-form-item>
              <!-- 商品码 -->
              <a-form-item
                label="商品码"
                required
              >
                <!-- <span v-if="$route.query.type == 3">{{form.goods_bn}}</span> -->
                <!-- defaultValue form.goods_bn -->
                <a-input
                  v-model="form.goods_bn"
                  class="ant-input_1 wid-488"
                  :maxLength="20"
                  allowClear
                  oninput="value=value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, '')"
                  placeholder="产品名称首字母+4位随机数"
                />
              </a-form-item>

              <!-- 类别 -->
              <div class="samerow flex">
                <a-form-item
                  label="类别"
                  required
                  class="samerow1"
                >
                  <a-select
                    placeholder="选择类别"
                    v-model="form.goods_type"
                    allowClear
                    :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                    style="min-width:154px;max-width:154px;display: inline-block;"
                  >

                    <a-select-option
                      v-for="item in typesList"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <a-form-item
                    label="财务分类"
                    required
                    class="samerow2"
                    style="margin-bottom:0px"
                  >
                    <template>
                      <span
                        style="width:auto;position: relative;"
                        id="styledatas"
                        class="styledatas"
                      >
                        <a-cascader
                          v-model="form.finance_category_id"
                          :options="treeData2"
                          placeholder="分类"
                          allowClear
                          @change="onChange"
                          style="min-width: 260px;"
                          :fieldNames="selectreeObj2"
                          :getPopupContainer="getPopupContainer"
                        >
                        </a-cascader>
                      </span>

                    </template>
                  </a-form-item>
                </a-form-item>
              </div>
              <!-- 品牌 -->
              <div class="samerow flex">
                <a-form-item
                  label="品牌"
                  class="samerow1"
                >
                  <a-select
                    placeholder="选择品牌"
                    v-model="form.goods_brand"
                    allowClear
                    :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                    style="min-width:154px;max-width:154px;display: inline-block;"
                  >

                    <a-select-option
                      v-for="item in brandsList"
                      :key="item.brand_id"
                    >
                      {{ item.brand_name }}
                    </a-select-option>
                  </a-select>
                  <a-form-item
                    label="门店分类"
                    class="samerow2"
                    style="margin-bottom:0px"
                  >
                    <template>
                      <span
                        style="width:auto;position: relative;"
                        id="styledatas2"
                        class="styledatas2"
                      >
                        <a-cascader
                          v-model="form.goods_category"
                          :options="treeData"
                          placeholder="分类"
                          allowClear
                          @change="onChange"
                          style="min-width: 260px;"
                          :fieldNames="selectreeObj"
                          :getPopupContainer="getPopupContainer2"
                        ></a-cascader>
                      </span>

                    </template>
                  </a-form-item>
                </a-form-item>
              </div>
              <!-- 合作项目 -->
              <div class="samerow flex">
                <a-form-item
                  label="合作项目"
                  required
                  class="samerow1"
                >
                  <div
                    class=" flex align-item-center"
                    style=""
                  >
                    <div
                      class="checkqystatus   flex align-item-center"
                      style="width:154px;"
                    >
                      <span
                        class="cqt1"
                        @click="form.goods_type_hz=0"
                        :class="form.goods_type_hz==0? 'cqt1A':''"
                      >否</span>
                      <span
                        class="cqt1"
                        @click="form.goods_type_hz=1"
                        :class="form.goods_type_hz==1? 'cqt1A':''"
                      >是</span>
                    </div>
                  </div>
                  <a-form-item
                    label="商城分类"
                    class="samerow2"
                    style="margin-bottom:0px"
                  >
                    <template>
                      <span
                        style="width:auto;position: relative;"
                        id="styledatas3"
                        class="styledatas3"
                      >
                        <a-cascader
                          v-model="form.store_category_id"
                          :options="treeData3"
                          allowClear
                          placeholder="分类"
                          @change="onChange"
                          style="min-width: 260px;"
                          :fieldNames="selectreeObj3"
                          :getPopupContainer="getPopupContainer3"
                        />
                      </span>

                    </template>
                  </a-form-item>
                </a-form-item>
              </div>
              <!-- 标准单位 -->
              <a-form-item
                label="标准单位"
                required
              >
                <a-select
                  placeholder="选择单位"
                  allowClear
                  :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                  v-model="form.goods_unit"
                  style="width: 154px"
                >
                  <a-select-option
                    value="个"
                    key="个"
                  >
                    个
                  </a-select-option>
                  <a-select-option value="盒">
                    盒
                  </a-select-option>
                  <a-select-option value="瓶">
                    瓶
                  </a-select-option>
                  <a-select-option value="支">
                    支
                  </a-select-option>
                  <a-select-option value="箱">
                    箱
                  </a-select-option>
                  <a-select-option value="克">
                    克
                  </a-select-option>
                  <a-select-option value="ml">
                    ml
                  </a-select-option>
                </a-select>
              </a-form-item>
              <!-- 赠送 -->
              <a-form-item
                label="赠送"
                required
              >
                <div
                  class="checkqystatus flex align-item-center"
                  style="width:214px;"
                >
                  <span
                    class="cqt1"
                    style="width:106px"
                    @click="form.is_give=1"
                    :class="form.is_give==1? 'cqt1A':''"
                  >允许赠送</span>
                  <span
                    class="cqt1"
                    style="width:106px"
                    @click="form.is_give=0"
                    :class="form.is_give==0? 'cqt1A':''"
                  >不允许赠送</span>
                </div>
              </a-form-item>
              <!-- 状态 -->
              <div class="samerow flex">
                <a-form-item
                  label="状态"
                  required
                  class="samerow1"
                >
                  <div class="checkqystatus flex align-item-center">
                    <span
                      class="cqt1"
                      @click="form.is_show=1"
                      :class="form.is_show==1? 'cqt1A':''"
                    >在售</span>
                    <span
                      class="cqt1"
                      @click="form.is_show=0"
                      :class="form.is_show==0? 'cqt1A':''"
                    >停售</span>
                  </div>

                  <!-- <a-form-item
                    label="销售时间"
                    required
                    class="samerow2"
                    style="margin-bottom:0px"
                    v-if="form.is_show==1"
                  >

                    <span
                      style="min-width: 364px;display:inline-block;"
                      class="flex align-item-center"
                    >
                      <a-radio-group
                        name="radioGroup"
                        class="nomal_radio radio_nomal1"
                        v-model="form.goods_shou_type"
                      >
                        <a-radio :value="1">
                          长期
                        </a-radio>
                        <a-radio :value="2">
                          截止
                        </a-radio>
                      </a-radio-group> 
                      <a-date-picker
                        v-if="form.goods_shou_type==2"
                        v-model="form.goods_shou_end_time"
                        :class="form.goods_shou_type==2?'jzpicker1':'jzpicker'"
                        @change="onChangeTimes"
                        @openChange="isactive = !isactive"
                        format="YYYY-MM-DD HH:mm:ss"
                        :disabled-date="disabledDate"
                        :show-time="{}"
                        class="data_picker"
                      >
                        <i
                          slot="suffixIcon"
                          class="meiye-icon meiye-rili-moren"
                          :class="{ 't-c-g': isactive }"
                          style="font-size:12px;color: @baseColor40;background: @baseColor41;margin-top: -5px;"
                        ></i>
                      </a-date-picker>
                    </span>
                  </a-form-item> -->
                </a-form-item>
              </div>
              <a-form-item
                label=" "
                class="mgt-12"
              >
                <a-button
                  type="primary"
                  @click="onStep(2)"
                  class="bigheightbn wid-80"
                >下一步</a-button>
                <a-button
                  class="mgl-20 normalbtn bigheightbn wid-80"
                  @click="cancel()"
                >取消</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <!-- 第二步 -->
        <div
          class="two"
          v-if="step===2"
        >
          <div class="zengpinstep zengpinstep2">
            <h2 class="twoh2 mgb-32">
              <span class="tpointer"></span>
              <span class="ttxt">规格设置</span>
            </h2>
            <div class="mgl-11">
              <a-row
                class="flex align-item-center"
                style="margin-bottom:42px;"
              >
                <a-col :span="4">
                  <div class="textColor1">产品规格 </div>
                </a-col>
                <a-col :span="19">
                  <div class=" ">
                    <a-radio-group
                      v-model="goods_specs.isSingle"
                      class="lable_radiogroup"
                      style="width:324px;"
                      @change="changeGglable"
                    >
                      <a-radio-button
                        :value="1"
                        style="width:162px;text-align: center;"
                      >
                        单规格
                      </a-radio-button>
                      <a-radio-button
                        :value="2"
                        style="width:162px;text-align: center;"
                        @click="isShowGuigeAddSelect=true"
                      >
                        多规格
                      </a-radio-button>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row>
              <div
                class="sigleguige"
                v-if="goods_specs.isSingle==1"
              >
                <div class="tabelHeader">
                  <span class="tabelHeader_items">规格图</span>
                  <span class="tabelHeader_items tabelHeader_items-278">标准单价 <span
                      class="xing"
                      style="color:#F35E67"
                    >*</span></span>
                  <span class="tabelHeader_items tabelHeader_items-278">货品码
                    <span
                      class="xing"
                      style="color:#F35E67"
                    >*</span>
                  </span>
                  <span class="tabelHeader_items tabelHeader_items-278">市场价 </span>
                </div>
                <div class="tableBody">
                  <div class="uploaderguige tabelBody_items">
                    <a-upload
                      name="file"
                      list-type="picture-card"
                      class="avatar-uploader"
                      :show-upload-list="false"
                      :action="baseUrl+'/upload'"
                      :headers="headers"
                      :before-upload="beforeUpload"
                      :customRequest="uploadFiles"
                      @change="handleChange1"
                    >
                      <img
                        v-if="form.goods_attr_val[0].product_image"
                        :src="form.goods_attr_val[0].product_image"
                        alt="avatar"
                      />
                      <div v-else>
                        <a-icon :type="permissionInfo.url_loading ? 'loading' : 'plus'" />
                      </div>
                    </a-upload>
                  </div>
                  <div class="danjia tabelBody_items tabelBody_items-278">
                    <a-input
                      style="width:138px;"
                      class="ant-input_1"
                      allowClear
                      prefix="￥"
                      v-model="form.goods_attr_val[0].product_price"
                      placeholder=""
                    >
                    </a-input>
                  </div>

                  <div class="txcode tabelBody_items tabelBody_items-278">
                    <a-input
                      style="width:180px;"
                      allowClear
                      class="ant-input_1"
                      v-model="form.goods_attr_val[0].product_bn"
                      placeholder="格式：字母+数字组合"
                    ></a-input>
                  </div>
                  <div class="danjia tabelBody_items">
                    <a-input
                      style="width:138px;"
                      allowClear
                      class="ant-input_1"
                      prefix="￥"
                      v-model="form.goods_attr_val[0].product_market_price"
                      placeholder=""
                    >
                    </a-input>
                  </div>
                </div>
              </div>
              <!-- 多规格 -->

              <div
                v-else
                class="moreguige"
              >
                <a-row
                  class="flex "
                  style="margin-bottom:42px;flex-wrap:wrap"
                >
                  <a-col :span="4">
                    <div class="textColor1 chooseSku">选择规格 </div>
                    <span
                      class="font-22"
                      v-if="$route.query.type == 3"
                    >{{form.share_price}}</span>
                  </a-col>
                  <a-col :span="19">
                    <div
                      v-if="false"
                      class="guigeBox flex"
                      style="margin-right:44px;width: 100%;flex-wrap: wrap;"
                    >
                      <div class="guigeBox_left">
                        <div
                          class="algeGuigeleft flex"
                          style="width: 100%;flex-wrap: wrap;"
                          v-if="goods_specs.new_goods_specs_list&&goods_specs.new_goods_specs_list.length"
                        >
                          <!-- 添加规格名 规格值 -->
                          <div
                            class="goods_specx_add"
                            style="width:300px;margin-right:40px;margin-bottom:40px;"
                            v-for="(it,idx) in goods_specs.new_goods_specs_list"
                            :key="idx+'k'"
                          >
                            <p
                              class="flex align-item-center"
                              style="font-size: 18px; font-weight: 500;color: #333333;"
                            >
                              {{ goods_specs.new_goods_specs_list[idx].attr_name }}
                              <a-icon
                                style="margin-left:6px;display:inline-block;width: 24px;height: 24px;line-height:28px;background: #F0F5F4;border-radius: 4px;color:#819190;"
                                type="delete"
                                class="pointer"
                                @click="deleteNewspecs( idx,it )"
                              ></a-icon>
                            </p>
                            <div
                              class="flex align-item-center"
                              style="flex-wrap:wrap;"
                            >
                              <span style="font-size: 14px; font-weight: 400;color: #9EA6A4;margin-right:6px;">规格值:</span>
                              <div
                                class="mgr-24 flex"
                                style="position:relative; padding:2px 8px;background: #F3FAFA;margin-right:16px;"
                                v-for="(m,n) in goods_specs.new_goods_specs_list[idx].attr_values"
                                :key="n+'l'"
                              >
                                <div
                                  class="flex baseColor11"
                                  style=" margin:0 8px 0 0"
                                >{{ goods_specs.new_goods_specs_list[idx].attr_values[n] }}</div>

                                <i
                                  class="meiye-icon meiye-close-bold"
                                  @click="deletenewGoodsSpecs(idx,n)"
                                ></i>
                              </div>
                            </div>
                            <div class="guigeline"></div>
                          </div>

                        </div>

                      </div>

                      <div
                        class="guigeBox_rgt"
                        v-if="isShowGuigeAddSelect"
                      >
                        <div
                          class="flex "
                          style="flex-warp:nowrap;"
                        >
                          <a-select
                            allow-clear
                            class="pgb-20 selectGg"
                            placeholder="选择规格"
                            v-model="goods_specs.goods_specs_id"
                            style="width: 180px;margin-right:20px;"
                            @change="changeGoodsSpecs"
                            dropdownClassName="selectGg"
                          >
                            <a-select-option
                              v-for="it in goods_specs.goods_specs_list"
                              :key="it.id"
                              :value="it.id"
                            >
                              <i
                                class="meiye-icon meiye-routeicon-add-copy baseColor11"
                                v-if="it.id==9999"
                              ></i>
                              {{ it.attr_name }}
                            </a-select-option>
                          </a-select>
                          <a-button
                            type="primary"
                            @click="changeGoodsSpecs1"
                          > 确认</a-button>

                        </div>
                        <!-- 规格 列 -->
                        <div
                          class="guige_values flex align-item-center"
                          v-if="ischangeGoodsSpecs1"
                          style="margin-top:18px;"
                        >
                          <div
                            class="guige_values_options flex  align-item-center"
                            v-for="(item, index) in goods_specs.goods_specs_values_list"
                            :key="index"
                            style="padding:2px 8px;background: #F3FAFA;margin-right:16px; "
                          >
                            <div
                              class="flex baseColor11"
                              style=" margin:0 8px 0 0"
                              v-if="goods_specs.goods_specs_values_list[index]"
                            >
                              {{goods_specs.goods_specs_values_list[index]  }}
                            </div>
                            <div
                              v-else
                              class="flex htmlboxshops"
                              style="width:68px;height:24px;border:none;box-shadow:none;outline:none;overflow:hidden;white-space: Nowrap; "
                              contenteditable
                              v-html="goods_specs.goods_specs_values_list[index]"
                              @input="onqyChange($event,item,index)"
                            >
                            </div>
                            <i
                              class="meiye-icon meiye-close-bold"
                              @click="closegoodsSpecsValues(index)"
                            ></i>
                          </div>
                          <span
                            v-if="goods_specs.goods_specs_values_list.length"
                            class="pointer"
                            @click="legalObjadd()"
                            style="display:inline-block;padding:2px 8px; background: #F8F8F8;color: #768483;"
                          >添加值 + </span>
                        </div>
                      </div>
                    </div>

                    <!-- 新增规格 -->
                    <div
                      class="addGuigeRules"
                      v-if="goods_specs.new_goods_specs_list&&goods_specs.new_goods_specs_list.length&&false"
                    >
                      <span
                        class="pointer"
                        @click=";isShowGuigeAddSelect=true;"
                      > <i class="meiye-icon meiye-routeicon-add"></i>
                        添加/新增规格 </span>
                    </div>

                    <sku-setting
                      @getSpecArr="hideModal2"
                      :new_goods_specs_list="goods_specs.new_goods_specs_list"
                    ></sku-setting>
                  </a-col>
                </a-row>
                <!-- 批量设置 -->
                <div
                  v-if="goodSpecstableData && goodSpecstableData.length>0"
                  class="batchSet flex align-item-center"
                >
                  <div class="batchSet_h2">批量设置</div>
                  <div class="guigeImg flex  align-item-center">
                    <span class="guigeImg_txt">规格图：</span>
                    <a-upload
                      :show-upload-list="false"
                      :action="baseUrl+'/upload'"
                      :headers="headers"
                      list-type="picture-card"
                      class="avatar-uploader mgr-20"
                      :before-upload="beforeUpload"
                      :customRequest="(info) =>batchSetuploadspecFiles(info)"
                      style=""
                    >
                      <img
                        v-if="goods_specs.product_image"
                        :src="goods_specs.product_image"
                        alt="avatar"
                      />
                      <div v-else>
                        <a-icon type='plus' />
                      </div>
                    </a-upload>
                  </div>
                  <div class="singlePrice flex  align-item-center">
                    <span>
                      <!-- <span
                        class="xing"
                        style="color:#F35E67"
                      >*</span> -->
                      标准单价：</span>
                    <a-input
                      placeholder="标准单价"
                      allowClear
                      v-model="goods_specs.price"
                      class="mgr-20  ant-input_1"
                      style="width:180px;"
                      oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                    />
                    <span>货品码：</span>
                    <a-input
                      style="width:180px"
                      allowClear
                      placeholder="货品码"
                      class="ant-input_1 mgr-20  "
                      :maxlength="30"
                      v-model="goods_specs.barcode"
                    />
                    <span>市场价： </span>
                    <a-input
                      placeholder="市场价"
                      allowClear
                      v-model="goods_specs.product_market_price"
                      class="mgr-20    ant-input_1"
                      style="width:180px;"
                      oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                    />

                  </div>
                  <span
                    class=" mgl-20 pointer pladd"
                    @click="createdSpecsTable"
                    :class="(goods_specs.product_market_price || goods_specs.barcode || goods_specs.price ||goods_specs.product_image)?'baseColor11':'notClick'"
                  >批量添加</span>
                </div>
                <!-- 生成的表格 -->
                <div class="guigeTablebox">
                  <a-table
                    v-if="goodSpecstableData && goodSpecstableData.length>0"
                    :pagination="false"
                    row-key="product_id_add"
                    :columns="goods_specs_clomns"
                    :data-source="goodSpecstableData"
                    class="guigeTable"
                  >

                    <span slot="product_price">
                      标准单价<span
                        class="xing"
                        style="color:#F35E67"
                      >*</span>
                    </span>
                    <span slot="product_bn">

                      货品码 <span
                        class="xing"
                        style="color:#F35E67"
                      >*</span></span>
                    <template
                      slot="product_image"
                      slot-scope="action, record"
                    >
                      <a-upload
                        :show-upload-list="false"
                        :action="baseUrl+'/upload'"
                        :headers="headers"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :before-upload="beforeUpload"
                        :customRequest="(info) =>uploadspecFiles(info,record)"
                        style=""
                      >
                        <img
                          v-if="record.product_image"
                          :src="record.product_image"
                          alt="avatar"
                        />
                        <div v-else>
                          <a-icon type='plus' />
                        </div>
                      </a-upload>
                    </template>
                    <template
                      slot="product_price"
                      slot-scope="action, record"
                    >
                      <a-input
                        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                        style="width:138px;"
                        class="ant-input_1"
                        allowClear
                        v-model="record.product_price"
                        @change="(record)=>changeSingeSpecIpt(record,'product_price')"
                      />
                    </template>
                    <!-- product_market_price -->
                    <template
                      slot="product_market_price"
                      slot-scope="action, record"
                    >
                      <a-input
                        class="ant-input_1"
                        allowClear
                        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                        style="width:138px;"
                        v-model="record.product_market_price"
                        @change="(record)=>changeSingeSpecIpt(record,'product_market_price')"
                      />
                    </template>
                    <template
                      slot="product_bn"
                      slot-scope="action, record"
                    >
                      <a-input
                        class="ant-input_1"
                        allowClear
                        onkeyup="value=value.replace(/[\W]/g,'') "
                        :maxlength="30"
                        style="width:138px;"
                        v-model="record.product_bn"
                        @change="(record)=>changeSingeSpecIpt(record,'product_bn')"
                      />
                    </template>
                  </a-table>
                </div>

              </div>
            </div>
          </div>
          <div class="three-btns last-btns-step2">
            <a-space :size="20">
              <a-button
                type="primary"
                @click="onStep(3)"
                class="stepbtnnext1 bigheightbn wid-80"
              >下一步</a-button>
              <a-button
                class="stepbackbtn bigheightbn wid-80"
                @click="step = 1"
              >上一步</a-button>
              <a-button
                class="stepbackbtn bigheightbn wid-80"
                @click="cancel()"
              >取消</a-button>
            </a-space>

          </div>
        </div>
        <!-- 第三步 -->
        <div
          class="three"
          v-if="step===3"
        >
          <div class="zengpinstep">
            <h2 class="twoh2 mgb-32">
              <span class="tpointer"></span>
              <span class="ttxt">其他配置</span>
            </h2>
            <div class=" ">
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col
                  :span="4"
                  class="wid-132"
                >
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >轮播图 </div>
                </a-col>
                <a-col :span="19">
                  <div class=" ">
                    <upload-image
                      @getImageId="getDelImageId"
                      @delimageId="delDelimageId"
                      @getImageshow="getImageshow"
                      :multiple="5"
                      :img="form.goods_slider"
                      @draggable="handleDraggable"
                      ref="uploadCom"
                    ></upload-image>
                  </div>
                </a-col>
              </a-row>
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >封面 </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex"
                >
                  <div
                    class="span_toutu"
                    style="width:164px;"
                  >
                    <a-upload
                      name="avatar"
                      list-type="picture-card"
                      class="avatar-uploader2"
                      :show-upload-list="false"
                      :action="baseUrl+'/upload'"
                      :headers="headers"
                      :before-upload="beforeUpload"
                      :customRequest="ffuploadFiles"
                      @change="handleChange2"
                      style=""
                    >
                      <span
                        v-if="form.goods_image"
                        class="boldimgspan"
                      >
                        <i
                          @click.stop="form.goods_image='';permissionInfo.pic_url_loading=false"
                          class="meiye-icon meiye-close-bold meiye-close-boldimg"
                        ></i>
                        <img
                          :src="form.goods_image"
                          alt="avatar"
                          style="width:48px;height:48px;"
                        />
                      </span>

                      <div v-else>
                        <a-icon :type="permissionInfo.pic_url_loading ? 'loading' : 'plus'" />
                      </div>
                    </a-upload>
                    <span style="font-size: 12px;font-weight: 400;color: #9EA6A4;">建议尺寸750*750px</span>
                  </div>
                  <div class="desc_intor flex">
                    <span style="margin-right:36px;display:inline-block;font-size: 14px;font-weight: 400;color: rgba(45,56,53,0.8);">描述:</span>
                    <a-textarea
                      class="ant-input_1"
                      style="width: 365px;height: 72px;resize: none;"
                      v-model="form.goods_desc"
                      placeholder="请输入不超过200字描述"
                      :maxLength="200"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                  </div>

                </a-col>
              </a-row>
              <a-row
                class="flex "
                style="margin-bottom:40px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >产品详情 </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex"
                >
                  <shop-editor
                    class="goods_editor"
                    @transfer="getUser"
                    v-model="form.goods_content"
                  >
                  </shop-editor>
                </a-col>
              </a-row>
            </div>
            <div class="last-btns">
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">

                </a-col>
                <a-col :span="19">
                  <a-space :size="20">
                    <a-button
                      type="primary"
                      @click="okclickmod()"
                      class="stepbtnnext1  bigheightbn wid-80"
                    >保存</a-button>
                    <a-button
                      class="stepbackbtn   bigheightbn wid-80"
                      @click="step = 2"
                    >上一步</a-button>
                    <a-button
                      class="stepbackbtn   bigheightbn wid-80"
                      @click="cancel()"
                    >取消</a-button>
                  </a-space>
                </a-col>
              </a-row>

            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import {
  GetmanageGoodslist,
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类
  CreateGoods, UpdateGoods, GoodsInfo, GetgoodsbrandsoptionsList
} from '@/api/commodity'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import skuSetting from '../../../components/intimate/skuSetting/index.vue'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import { pinyin } from 'pinyin-pro';
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, skuSetting },
  data() {
    return {
      config,
      setId: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },
      treeData: [
      ],
      treeData2: [
      ],
      treeData3: [
      ],
      selectreeObj: {
        children: 'children',
        label: 'category_name',
        value: 'category_id'
      },
      selectreeObj2: {
        children: 'children',
        label: 'category_name',
        value: 'finance_category_id'
      },
      selectreeObj3: {
        children: 'children',
        label: 'category_name',
        value: 'store_category_id'
      },
      brandsList: [],//品牌列表
      typesList: [
        { name: '客装', id: 1 },
        { name: '院装', id: 2 },
      ],//类别
      isactive: false,
      // 规格相关
      goods_specs: {
        isSingle: 1,//1  1单规格 2多规格
        barcode: undefined,//批量设置货品码
        price: undefined,//批量设置单价
        product_market_price: undefined,//批量市场价
        product_image: undefined,//批量设置图片
        ischooseModehow: true,//选择规格model
        goods_attr: [
          // { attr_name: '', attr_values: '' ,id:0}
        ],
        goods_specs_id: undefined,
        goods_attr_val: [
        ],
        goods_specs_list: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        goods_specs_list0: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        // 总共的 总规格list
        new_goods_specs_list: [
        ],
        goods_specs_values_list: [],
        goods_specs_keys_list: [],
        // select暂存的规格 和规格值 确认之后才push
        goods_specs_selectobj: {

        }
      },
      ischangeGoodsSpecs1: true, //是否显示下拉框所属的规格
      showaddGoodsprivatespec: false,//自定义新增规格弹框
      singelguigeadd: {
        attr_name: '',
        attr_values: [],
      },
      // 表单头
      goods_specs_clomns: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          className: 'pdl-24',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          // title: '*标准单价',
          slots: { title: 'product_price' },
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },

        {
          // title: '*货品码',
          slots: { title: 'product_bn' },
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
      ],
      goodSpecstableData: [],
      goodSpecsSingletableData: [
        {
          product_price: undefined,
          product_market_price: undefined,
          product_image: undefined,
          product_bn: undefined,
          product_attr: '',
          product_is_def: 1,
          product_id: 0,
          product_cost_price: '',
          product_market_price: '',
          product_stock: ''
        }
      ],
      // 固定的规格和规格值
      forevergoodSpecsObj: {
        activegoodSpecs: undefined,
        forevergoodSpecsList: [
          { name: '部位', id: '1', children: [{ name: '全身', id: '2' }, { name: '背部', id: '3' }, { name: '面部', id: '4' }] },
          { name: '颜色', id: '5', children: [{ name: '红色', id: '6' }, { name: '白色', id: '7' }, { name: '黑色', id: '8' }] },
          { name: '尺寸', id: '9', children: [{ name: '大', id: '10' }, { name: '中', id: '11' }, { name: '小', id: '12' }] },
          { name: '新建规格', id: '9999' }
        ],
        // 
      },
      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值
      isShowGuigeAddSelect: true,
      form: {//表单数据
        goods_id: undefined,
        goods_name: undefined,
        goods_bn: undefined,
        goods_unit: undefined,
        is_give: 0,//0不允许 
        is_show: 1,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        goods_attr: undefined,
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: undefined,
        goods_image: undefined,
        goods_slider: undefined,
        goods_type: 1,
        goods_supplier: 1,
        goods_brand: undefined,
        finance_category_id: [],
        store_category_id: [],
        goods_category: [],
        goods_content: undefined,
        goods_shou_type: 1,
        goods_shou_end_time: null
      },
      // goods_bn: '',//自动生成首字母的
      step: 1,
      imgflag: false,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: undefined,//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: 1,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '<h1>q<strong>wqeddd</strong><span style=\"background-color: rgb(196, 29, 127);\">dxxxxx</span></h1><p>撒打 撒啊大</p>',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      skuTableListcouple: [],
    };
  },
  created() {
    this.getCompanyoptionList()
    this.getCompanyoptionList2()
    this.getCompanyoptionList3()
    this.getBrands()
    if (this.$route.query.type == 1) {
      this.addorupdateSpecFlag = 'add'
    }
    if (this.$route.query.type == 3) {
      this.addorupdateSpecFlag = 'update'
      this.openDatails()
    }
  },
  methods: {
    moment,
    // 获取详情信息
    getPartnerDetail(id) {
      partnerDetail({ id: id }).then(res => {
        console.log(res.data, "data");
        let list_no = [];
        let list_yes = [];
        res.data.commission_rule.forEach(element => {
          if (element.product_type == 1) {
            list_no.push(element);
          }
          if (element.product_type == 2) {
            list_yes.push(element);
          }
        });
        this.form = {
          ...res.data,
          share_price: res.data.subscription_setting.share_price,
          min_shares: res.data.subscription_setting.min_shares,
          premium_rate: res.data.subscription_setting.premium_rate,
          commission_rule_no: list_no,
          commission_rule_yes: list_yes,
        };
        // this.timeData = [res.data.start_date, res.data.end_date];
        this.application_timeData = [res.data.application_start_date, res.data.application_end_date]
        // console.log('this.timeData :>> ', this.timeData);
        console.log('this.form :>> ', this.form);
      })
    },
    // 获取方案 id
    getPartnerGenerated() {
      partnerGenerated().then((res) => {
        this.form.scheme_identifier = res.data.scheme_identifier;
      })
    },
    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    //上传
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_attr_val[0].product_image = response?.data?.url
        this.permissionInfo.url_loading = false
      }
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.pic_url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_image = response?.data?.url
        this.permissionInfo.pic_url_loading = false
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 方案期效
    // onChangeTime(rec) {
    //   console.log('rec :>> ', rec);
    //   this.form.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
    //   this.form.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    //   console.log('rec :>> ', this.form.start_date);
    //   console.log('rec :>> ', this.form.end_date);
    // },
    // 开放时间
    onChangeApplication(rec) {
      console.log('rec :>> ', rec);
      this.form.application_start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.form.application_end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    },
    onChangeIdentify(rec) {
      console.log('rec :>> ', rec);
      this.form.identify_end_date = rec ? rec.format('YYYY-MM-DD') : '';
    },
    handleOpenChange(status) {
      this.yearPickShow = status;
    },
    onChangeYear(rec) {
      console.log('rec :>> ', rec.format('YYYY'));
      this.form.dividend_ratio_year = rec ? rec.format('YYYY') : '';
      this.yearPickShow = false;
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    onStep(key) {
      let flag = false
      let flag2 = false
      if (key == 2) {
        if (!this.form.goods_name) return this.$message.warning('请填写产品名称！')
        if (!this.form.goods_bn) return this.$message.warning('请填写商品码！')
        if (!this.form.finance_category_id) return this.$message.warning('请选择财务分类！')
        if (!this.form.goods_unit) return this.$message.warning('请选择单位！')

        if (this.form.goods_shou_type == 2 && !this.form.goods_shou_end_time) return this.$message.warning('请填写截止日期！')
        if (this.form.goods_type == 1 && this.form.goods_type_hz == 0) {
          this.form.goods_type = 1
        }
        if (this.form.goods_type == 1 && this.form.goods_type_hz == 1) {
          this.form.goods_type = 3
        }
        if (this.form.goods_type == 2 && this.form.goods_type_hz == 0) {
          this.form.goods_type = 2
        }
        if (this.form.goods_type == 2 && this.form.goods_type_hz == 1) {
          this.form.goods_type = 4
        }
        if (this.form.store_category_id && this.form.store_category_id.length) {
          if (this.form.store_category_id.length < 3) {
            return this.$message.warning('必须选择三级，请完善商城分类再选择！')
          }

        }
        if (this.form.goods_category && this.form.goods_category.length) {
          if (this.form.goods_category.length < 3) {
            return this.$message.warning('必须选择三级，请完善门店分类再选择！')
          }
        }
        this.step = key
      }
      if (key == 3) {

        if (this.goods_specs.isSingle == 1) {
          if (!this.form.goods_attr_val[0].product_price) {
            // return this.$message.warning('请填写标准单价！')
            flag = true
          }
          if (!this.form.goods_attr_val[0].product_bn) {
            // return this.$message.warning('请填写商品码！')
            flag2 = true
          }

        } else {

          this.goodSpecstableData.forEach(el => {
            if (!el.product_price || !el.product_price.trim()) {
              flag = true
            }
            if (!el.product_bn || !el.product_bn.trim()) {

              flag2 = true
            }
          })

        }

        // 遍历看看 必填项 规格的 价格  规格 的bn
        if (flag) {
          return this.$message.warning('请填写标准单价！')
        }
        if (flag2) {
          return this.$message.warning('请填写商品码！')
        }
        if (!flag && !flag2) {
          this.step = key
        }



      }
      if (key == 4) {
        this.step = key
        delete this.form.goods_type_hz
      }
      this.step = key


    },
    //取消
    cancel() {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '当前已填写数据尚未保存，确定取消保存吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.$router.push({ path: '/shop/management/managementlist' })
          Msg.$emit('changethirdaryShow', true)
        },
        onCancel() { },
      })

    },
    handlePreview(src, type) { },
    addTableItem(key, type) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this['edit_item'] = undefined
      if (key == 'dividend_ratio_list') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          dividend_business: undefined,
          dividend_rate: undefined,
          id: new Date().getTime(),
        })
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          first_purchase_rate: undefined,
          payment_method: undefined,
          repurchase_rate: undefined,
          product_type: type,
          id: new Date().getTime(),
        })
      }
      console.log('this.form[key] :>> ', this.form[key]);
    },
    saveItem(record, index, key) {
      let self = this
      if (key == 'dividend_ratio_list') {
        console.log(record, index, key)
        const { dividend_rate } = record
        const specValueAntDElText = self.$refs['SpecValueRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!dividend_rate) {
          msg = '分红比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        const { first_purchase_rate, repurchase_rate } = record
        const specValueAntDElText = self.$refs['productTypeRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!first_purchase_rate) {
          msg = '首购付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!repurchase_rate) {
          msg = '再次付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      setTimeout(() => {
        self.form[key][index].editable = false
      }, 400)
    },
    editItem(id, key) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this.form[key] = this.form[key].map((it) => {
        if (it.id == id) {
          this['edit_item'] = it
          it.editable = true
        }
        return it
      })
    },
    cancelItem(index, key) {
      if (!this['edit_item']) {
        this.form[key].splice(index, 1)
        return
      }
      this.form[key] = this.form[key].map((it, ind) => {
        if (index == ind) {
          it.editable = false
        }
        return it
      })
    },
    deleteItem(record, index, key) {
      this.form[key].splice(index, 1)
    },
    editItemQy(record) {
      this.modal.form = record;
      this.qyStep = 1;
      this.modal.visible = true;
    },
    deleteItemQy(index) {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '是否删除？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.form.benefit.splice(index, 1)
        },
        onCancel() { },
      })
    },
    // 添加权益
    handleOk() {
      if (!this.modal.form.name) return this.$message.warning('请填写权益名称！')
      if (!this.modal.form.goods_content) return this.$message.warning('请填写权益详情！')
      this.modal.form.count = this.modal.form.count_type == 1 ? 0 : this.modal.form.count;
      this.form.benefit.push(this.modal.form);
      this.modal.visible = false;
      this.qyStep = 1
    },
    handleCancel() {
      this.qyStep = 1;
      this.modal.visible = false
    },
    concatFunction(arr1, arr2) {
      //不要直接使用var arr = arr1，这样arr只是arr1的一个引用，两者的修改会互相影响  
      var arr = arr1.concat();
      //或者使用slice()复制，var arr = arr1.slice(0)  
      for (var i = 0; i < arr2.length; i++) {
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
      }
      console.log('concatFunction', arr)
      return arr
    },
    submit() {

      if (this.form.is_all_shop == 2) {
        if (!this.withBrandCheck.length) return this.$message.warning('请选择指定门店！')
      }
      console.log('this.withBrandCheck :>> ', this.withBrandCheck);
      this.form.institution_ids = this.is_all_shop == 1 ? 'all' : this.withBrandCheck.toString();
      const { commission_rule_no, commission_rule_yes } = this.form;
      console.log('commission_rule_no :>> ', commission_rule_no);
      console.log('commission_rule_yes :>> ', commission_rule_yes);
      let arr = this.concatFunction(commission_rule_no, commission_rule_yes)
      let data = {
        ...this.form,
        commission_rule: arr,
      }
      delete data.commission_rule_no;
      delete data.commission_rule_yes;
      const hide = this.$message.loading('正在保存..', 0)
      if (this.$route.query.type == 2) {
        partnerSave(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
      if (this.$route.query.type == 1) {
        partnerAdd(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
    },
    goStep(e) {
      if (this.$route.query.type != 1) {
        this.step = e
      }
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)

      this.$forceUpdate();
    },
    //  门店分类 树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'category_id')
        this.treeData = res.data
        console.log(this.treeData, '>>>this.coptionsList');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.form.company_parent = res.data[0].company_id
          // this.getList()
        }

      })
    },
    //  财务分类 树形列表
    getCompanyoptionList2(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data, 'finance_category_id')
        this.treeData2 = res.data
        console.log(this.treeData2, '>>>this.treeData2');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.form.company_parent = res.data[0].company_id
          // this.getList()
        }

      })
    },
    //  商城分类 树形列表
    getCompanyoptionList3(e) {
      TreetypeMallList().then(res => {
        this.treeRecursion(res.data, 'store_category_id')
        this.treeData3 = res.data
        console.log(this.treeData3, '>>>this.coptionsList3');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.form.company_parent = res.data[0].company_id
          // this.getList()
        }

      })
    },
    // 递归改变id key number
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children, str);
      })
    },
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          // this.brandsList.forEach(el => {
          //   el.brand_id = el.brand_id.toString()
          // })
          // this.brandsList.unshift({
          //   brand_icon: "meiye-routeicon-chart-pie",
          //   brand_id: 99999,
          //   brand_name: "全部",
          //   brand_sort: 1,
          //   brand_status: 1,
          //   created_at: "2023-04-20 16:30:25",
          // })
        }
      })
    },
    openDatails() {
      GoodsInfo({ goods_id: this.$route.query.id }).then(res => {
        console.log(this.brandsList);
        this.form = Object.assign(this.form, res.data)
        let str = res.data.goods_bn
        console.log(str);
        console.log(res.data);
        this.goods_name = this.form.goods_name

        console.log(this.form.goods_bn, "goods_bn");
        this.form.goods_unit = this.form.goods_unit ? this.form.goods_unit : undefined
        this.form.goods_type = this.form.goods_type == 3 ? 1 : this.form.goods_type == 1 ? 1 : 2
        this.form.goods_brand = this.form.goods_brand ? this.form.goods_brand : undefined
        this.form.goods_supplier = this.form.goods_supplier + ''
        this.form.goods_slider = JSON.parse(this.form.goods_slider)
        this.form.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = el.goods_id
        })

        // [] 
        if (this.form.goods_category) {
          this.form.goods_category = (res.data.category_info.category_parent_path + ',' + (res.data.category_info.category_id + '')).split(',')

        } else if (this.form.goods_category == 0) {
          this.form.goods_category = undefined
        }
        if (this.form.store_category_id) {
          this.form.store_category_id = (res.data.store_category_info.category_parent_path + ',' + (res.data.store_category_info.store_category_id + '')).split(',')

        } else if (this.form.store_category_id == 0) {
          this.form.store_category_id = undefined
        }
        // store_category_info
        // this.form.store_category_id = (res.data.store_category_info.category_parent_path + ',' + (res.data.store_category_info.store_category_id + '')).split(',')
        if (res.data.finance_category_id) { this.form.finance_category_id = [res.data.finance_category_id + ""] } else { this.form.finance_category_id = undefined }
        // 单规格 or 多规格
        let self = this
        if (this.form.goods_attr && this.form.goods_attr.length) {//多规格
          this.goods_specs.isSingle = 2
          this.goods_specs.new_goods_specs_list = []
          this.goods_specs.goods_specs_keys_list = []
          //  这里多规格 回显设置规格的值列表
          this.form.goods_attr.forEach((el, idx) => {
            let obj = {
              id: "p" + idx,
              attr_name: el.attr_name,
              attr_values: el.attr_values
            }

            self.goods_specs.new_goods_specs_list.push(obj)
          })

          console.log(this.goods_specs.new_goods_specs_list);

          // 还需要回显规格商品表
          this.goodSpecstableData = []

          // 处理 goods_specs_clomns
          let config_clomns = []
          // this.goods_specs_clomns = []
          let arri1 = []
          this.form.goods_attr.forEach((el, idx) => {
            let obj = {
              title: el.attr_name,
              dataIndex: el.attr_name,
              key: el.attr_name,
              ellipsis: true,
              width: 180,
              scopedSlots: { customRender: el.attr_name },
              className: 'datestyle',
            }
            let object1 = {
              attr_name: el.attr_name,
              attr_values: el.attr_values,
              product_id: el.product_id
            }
            config_clomns.push(obj)
            arri1.push(object1)
          })
          this.form.goods_attr = arri1
          console.log(this.goods_specs_clomns);

          this.goods_specs_clomns = [...config_clomns, ... this.goods_specs_clomns]
          console.log(this.goods_specs_clomns, "...this.goods_specs_clomns");
          let newResult = arri1.reduce((a, b, c) => {
            let res = []
            a.map(x => {
              b.attr_values.map(y => {
                res.push({ ...x, [b.attr_name]: y })
              })
            })
            return res
          }, [{}])
          // var keyMap = (o) => fun => {
          //   var newObject = {}
          //   Object.keys(o).forEach(key => {
          //     newObject[fun(key)] = o[key]
          //   })
          //   return newObject
          // } 
          // newResult = keyMap(newResult)(e => `"${e}"`)
          newResult.forEach((el, idx) => {

            let obj = {
              product_id: this.form.goods_attr_val[idx].product_id,
              product_bn: this.form.goods_attr_val[idx].product_bn,
              product_attr: this.form.goods_attr_val[idx].product_attr,
              // product_attr: {"颜色": "白色", "量装": "瓶装", "套装": "25"}
              product_is_def: this.form.goods_attr_val[idx].product_is_def,
              product_image: this.form.goods_attr_val[idx].product_image,
              product_price: this.form.goods_attr_val[idx].product_price,
              product_market_price: this.form.goods_attr_val[idx].product_market_price,
              product_cost_price: this.form.goods_attr_val[idx].product_cost_price,
              product_market_price: this.form.goods_attr_val[idx].product_market_price,
              product_stock: this.form.goods_attr_val[idx].product_stock
            }
            for (const i in el.product_attr) {
              el[j] = el.product_attr[i];
            }
            el = Object.assign(el, obj)
            delete el.product_attr
          })
          this.goodSpecstableData = newResult
          console.log(this.goods_specs.new_goods_specs_list, "goods_specs.new_goods_specs_list");

        } else {//单规格
          this.goods_specs.isSingle = 1
          this.goodSpecsSingletableData = this.form.goods_attr_val
        }

        let fileList = []
        // 回显轮播图
        this.form.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })

        if (this.$refs.uploadCom && this.$refs.uploadCom.fileList) {
          this.$refs.uploadCom.fileList = fileList
        }
        // 回显bn
        this.form.goods_bn = str

      })
    },
    onChange() { },
    onChangeTimes(date, dateString) {
      this.form.goods_shou_end_time = dateString
    },
    onChange1() { },
    getPopupContainer(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas')[0]
    },
    getPopupContainer2(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas2')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas2')[0]
    },
    getPopupContainer3(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas3')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas3')[0]
    },
    // 单规格 上传规格图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {

        this.form.goods_attr_val[0].product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    // 选择规格
    changeGoodsSpecs(e, i) {
      let self = this
      if (e == 9999) {
        this.goods_specs.goods_specs_id = undefined
        this.showaddGoodsprivatespec = true //自定义新增规格弹框
        this.ischangeGoodsSpecs1 = false
        return false
      }
      this.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          self.goods_specs.goods_specs_values_list = el.attr_values
          //  
          // self.goods_specs.new_goods_specs_list.push(el)
          this.goods_specs.goods_specs_selectobj = el
        }

      })
      this.ischangeGoodsSpecs1 = true
      console.log(this.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");

    },
    // 确认规格
    changeGoodsSpecs1() {
      // 确认没有空的规格值  然后push

      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      // this.goods_specs.goods_specs_values_list.push('')
      console.log(this.goods_specs.goods_specs_selectobj);
      this.goods_specs.new_goods_specs_list.push(this.goods_specs.goods_specs_selectobj)
      this.goods_specs.goods_specs_list.forEach((el, idx) => {
        if (el.id == this.goods_specs.goods_specs_selectobj.id) {

          this.goods_specs.goods_specs_list.splice(idx, 1)
          this.goods_specs.goods_specs_id = undefined
        }
      })
      console.log(this.goods_specs.goods_specs_list);
      // 生成多规格列表
      this.hideModal()
      this.ischangeGoodsSpecs1 = false
      this.isShowGuigeAddSelect = false
    },
    // 
    onqyChange(e, it, idex) {
      let self = this
      let a = self.goods_specs.goods_specs_values_list[idex]
      console.log(e, self.goods_specs.goods_specs_values_list[idex]);
      console.log(this.goods_specs.goods_specs_values_list);

      self.goods_specs.goods_specs_values_list[idex] = e.target.innerText
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    legalObjadd() {
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      this.goods_specs.goods_specs_values_list.push('')
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e, it) {
      let self = this
      // delete this.goods_specs.new_goods_specs_list[e]
      this.goods_specs.new_goods_specs_list.splice(e, 1)
      // 恢复 goods_specs_list 的值
      this.goods_specs.goods_specs_list0.forEach(el => {
        if (el.id == it.id) {
          self.goods_specs.goods_specs_list.unshift(it)
        }
      })
      // 重新 生成多规格列表
      this.hideModal()
      console.log(this.goods_specs.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    deletenewGoodsSpecs(idx, n) {
      // 调取 如果

      let obj = {}
      this.goods_specs.goods_specs_list0.forEach((el, index) => {
        if (el.attr_name == this.goods_specs.new_goods_specs_list[idx].attr_name) {
          obj = el
        }
      })
      this.goods_specs.new_goods_specs_list[idx].attr_values.splice(n, 1)
      if (this.goods_specs.new_goods_specs_list[idx].attr_values.length == 0) {
        this.goods_specs.new_goods_specs_list.splice(idx, 1)
        if (obj.length) this.goods_specs.goods_specs_list.unshift(obj)
      }
      // console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      // 选择框 加入 删除的选项

      this.hideModal()
    },
    // 批量生成规格表格  设置 单价 和 货品码
    createdSpecsTable() {
      let self = this

      self.goods_specs
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].product_price = self.goods_specs.price
        self.goodSpecstableData[idx].product_market_price = self.goods_specs.product_market_price
        self.goodSpecstableData[idx].product_bn = self.goods_specs.barcode
        self.goodSpecstableData[idx].product_image = self.goods_specs.product_image

      })
      //  
      console.log(this.goodSpecstableData, "vvvthis.goodSpecstableData");
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");
      //  
      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      }

    },

    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.goods_specs.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      this.goods_specs.new_goods_specs_list[idx].attr_values.push('')
    },
    addnewgoodsspecs1() {
      let flag = false
      let self = this
      if (this.singelguigeadd.attr_values.length) {
        this.singelguigeadd.attr_values.forEach(el => {
          if (!el) {
            flag = true
          }
        })
        if (flag && self.singelguigeadd.attr_values.length > 0) {
          this.$warning({
            title: '提示',
            content: h => {
              return h('div', [
                h(
                  'span',
                  [' 信息尚未填写，请完善内容后操作。']
                ),
              ])
            },

            okText: '好的',
            okType: 'primary',
            // cancelText: '取消',
            onOk() {
            },
          });
          return false
        }
      } else {
        // this.singelguigeadd[idx].attr_values.push('')
      }

      console.log(this.singelguigeadd.attr_values);
      this.singelguigeadd.attr_values.push('')
    },
    addguigeokFn(e) {

      console.log(e, 'addguigeokFn');
      console.log(this.singelguigeadd, 'singelguigeadd');
      this.goods_specs.new_goods_specs_list.push(this.singelguigeadd)
      this.singelguigeadd = {
        attr_name: '',
        attr_values: [],
      }
      this.hideModal()
      this.showaddGoodsprivatespec = false
      this.isShowGuigeAddSelect = false
    },
    // 生成多规格table
    hideModal() {
      console.log('往表格里面加规格列表');

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //  
      arr = [...this.goods_specs.new_goods_specs_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          className: 'pdl-24',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          // title: '*标准单价',
          slots: { title: 'product_price' },
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          // title: '*货品码',
          slots: { title: 'product_bn' },
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: el.attr_name },
          className: 'datestyle',
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.form.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      // 
      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表

      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {

        newResult.forEach((el, idx) => {
          this.form.goods_attr_val.forEach((m, n) => {
            //  
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
      }

      this.goods_specs.ischooseModehow = false

    },
    hideModal2(new_goods_specs_list) {

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list = new_goods_specs_list
      this.goods_specs.goods_specs_list

      arr = [...this.goods_specs.new_goods_specs_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image', className: 'pdl-24',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          // title: '*标准单价',
          slots: { title: 'product_price' },
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          // title: '*货品码',
          slots: { title: 'product_bn' },
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
      ]

      console.log(arr);
      // let object1 = {
      //   attr_name: arr[0].attr_name,
      //   attr_values: arr[0].attr_values,
      // }
      // let object2 = {
      //   title: arr[0].attr_name,
      //   dataIndex: arr[0].attr_name,
      //   key: arr[0].attr_name,
      //   ellipsis: true,
      //   scopedSlots: { customRender: arr[0].attr_name },
      // }
      // arri1 = object1
      // columns = object2 
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: el.attr_name },
          className: 'datestyle',
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.form.goods_attr = arri1 //规格   
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      let newResult2 = []
      // product_attr

      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      console.log(newResult, 1, newResult2)
      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update' && false) {
        newResult.forEach((el, idx) => {
          this.form.goods_attr_val.forEach((m, n) => {
            //  
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
            }
          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {
        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: {},
            //  "颜色": "白色", "大小": "黑色"
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
        this.form.goods_attr_val = newResult2
      }

      // 2
      this.goods_specs.ischooseModehow = false

    },
    // 批量 规格图
    async batchSetuploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.goods_specs.product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 列表 规格图
    // 规格图
    async uploadspecFiles(info, record, flag) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {

            if (record.product_id >= 0) {
              if (el.product_id == record.product_id) {
                el.product_image = res.data.url
              }
            }
            if (record.product_id_add >= 0) {
              if (el.product_id_add == record.product_id_add) {
                el.product_image = res.data.url
              }
            }

          })
        }


        // this.form.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 轮播图
    getDelImageId(val) {
      if (this.form.goods_slider && this.form.goods_slider.length > 0) {

      } else {
        this.form.goods_slider = []
      }
      this.form.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.form.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.form.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.form.goods_slider = imgDrag
    },
    // 商品 f封面图
    async ffuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.form.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 提交按钮
    okclickmod(e) {

      function fn(beforeData, addorupdateSpecFlag) {
        var afterData = []
        beforeData.forEach(item => {
          let flag
          if (addorupdateSpecFlag == 'add') {
            flag = afterData.find(item1 => item1.product_id && item.product_id && item1.product_id == item.product_id)
          } else {
            flag = afterData.find(item1 => item1.product_id_add == item.product_id_add)
          }

          if (!flag) {
            if (addorupdateSpecFlag == 'add') {
              afterData.push({
                product_id: item.product_id,
                product_attr: [item]
              })
            } else {
              afterData.push({
                product_id_add: item.product_id_add,
                product_attr: [item]
              })
            }

          } else {
            flag.product_attr.push(item)
          }
        })
        console.log(afterData, 'afterDataafterData');
        return afterData

      }
      function chkstrlen(str) {
        var strlen = false;
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 255) //如果是汉字， 
            strlen = true;
        }
        return strlen;
      }

      let data_pa = {
        ...this.form
      }
      // 这里要搞事情 goods_specs.isSingle  生成多规格商品列表 goodSpecstableData
      if (this.goods_specs.isSingle != 1) {
        // 多规格
        this.form.goods_attr_val = this.goodSpecstableData
        let arry = []
        this.form.goods_attr_val.forEach(el => {
          this.form.goods_attr.forEach((m, n) => {
            let obj = {}
            if (el.hasOwnProperty(m.attr_name)) {
              //  
              obj[m.attr_name] = el[m.attr_name]
              obj.product_id = el.product_id
              obj.product_id_add = el.product_id_add
              arry.push(obj)
            }
          })
        })
        console.log(arry, "mainarr....");
        let mainarr = fn(arry, this.addorupdateSpecFlag)
        console.log(mainarr, '/.//mainarr2');

        console.log(mainarr, "mainarr.3...");
        console.log(this.form.goods_attr_val, 'this.form.goods_attr_val');
        this.form.goods_attr_val.forEach(el => {
          el.product_attr = []
          mainarr.forEach((m, n) => {
            m.product_attr.forEach(j => {
              if (el.product_id > -1) {
                if (el.product_id == j.product_id) {
                  delete j.product_id
                  delete j.product_id_add
                  el.product_attr.push(j)
                }

              }
              if (el.product_id_add > -1) {
                if (el.product_id_add == j.product_id_add) {
                  delete j.product_id
                  delete j.product_id_add
                  el.product_attr.push(j)
                }
              }
            })


          })
        })
        // 

        console.log(this.form.goods_attr_val, " this.form.goods_attr_val");
        this.form.goods_attr_val.forEach((el, idx) => {
          if (el.product_attr.length > 1) {
            let s = JSON.stringify(el.product_attr)
            el.product_attr = JSON.parse(s.replace(/},{/g, ","))[0]
          } else if (el.product_attr.length == 1) {
            el.product_attr = el.product_attr[0]
          } else {
            el.product_attr = []
          }
          delete el.product_id
          delete el.product_id_add
          delete el.product_attr['"product_id"']
          delete el.product_attr['"product_id_add"']
          delete el.product_attr["product_id_add"]
          delete el.product_attr["product_id"]
          delete el.product_attr['"created_at"']
          delete el.product_attr['"updated_at"']
          delete el.created_at
          delete el.updated_at

          for (var pl in el) { //数组对象遍历
            console.log(pl); //获取key
            console.log(el[pl]) //获取key的值
            let flag = chkstrlen(pl) //判断是否中文字符
            if (flag) {
              delete el[pl]
            }
          }
        })
        console.log(this.form.goods_attr_val);

      } else {
        // 单规格 
        this.form.goods_attr = undefined
        // this.form.goods_attr_val.forEach((el, idx) => {
        //   el.product_attr = []
        // })
        let obj = {
          product_image: this.form.goods_attr_val[0].product_image,
          product_price: this.form.goods_attr_val[0].product_price,
          product_bn: this.form.goods_attr_val[0].product_bn,
          product_market_price: this.form.goods_attr_val[0].product_market_price
        }
        this.form.goods_attr_val = [obj]
      }
      data_pa = {
        ...this.form
      }
      if (this.form.finance_category_id && this.form.finance_category_id.length) {
        data_pa.finance_category_id = this.form.finance_category_id.length ? this.form.finance_category_id[0] : ''
      }
      if (this.form.goods_category && this.form.goods_category.length) {
        data_pa.goods_category = this.form.goods_category.length ? this.form.goods_category.pop() + '' : undefined
      } else {
        data_pa.goods_category = 0
      }
      if (this.form.store_category_id && this.form.store_category_id.length) {
        data_pa.store_category_id = this.form.store_category_id.length ? this.form.store_category_id.pop() + '' : undefined
      } else {
        data_pa.store_category_id = 0
      }
      delete data_pa.pic_url_loading
      // 区分新增 修改接口
      if (this.$route.query.type == 3) {
        if (data_pa.category_info) delete data_pa.category_info
        if (data_pa.store_category_info) delete data_pa.store_category_info
        if (data_pa.created_at) delete data_pa.created_at
        if (data_pa.updated_at) delete data_pa.updated_at
        if (data_pa.finance_category_info) delete data_pa.finance_category_info

        UpdateGoods(data_pa).then(res => {
          if (res.error_code == 0) {
            this.$message.success('修改成功')
            this.changeRoute()
          }
        })
      } else {
        //新增 
        delete data_pa.goods_id
        delete data_pa.product_id
        delete data_pa.product_id_add
        delete data_pa.goods_type_hz
        CreateGoods(data_pa).then(res => {

          if (res.error_code == 0) {
            this.$message.success('创建成功')
            // this.getList()
            this.changeRoute()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    changeGglable(e) {
      // if (e.target.value == 1  && this.form.goods_attr.length > 0) {
      if (e.target.value == 1 && this.$route.query.type == 3) {
        this.goods_specs.isSingle = 2
        let self = this
        this.$confirm({
          icon: 'exclamation-circle',
          closable: true,
          title: '提示',
          content: '切换规格模式，将自动下架各渠道的全部规格，需重新上架至各渠道，请谨慎操作！',
          okText: '确定',
          okType: 'primary',
          cancelText: '取消',
          onOk() {
            self.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '提示',
              content: '  规格模式已切换至【单规格】模式，各渠道已下架当前商品，请重新上架新规格至各销售渠道；',
              okText: '确定',
              okType: 'primary',
              cancelText: '取消',
              onOk() {

                self.goods_specs.isSingle = 1
                self.form.goods_attr = undefined
                self.form.goods_attr_val = [{ product_image: '', product_price: '', product_bn: "" }]

              },
              onCancel() { },
            })
          },
          onCancel() { },
        })
      }
    },

  },
  computed: {
    goods_name: {
      get() {
        return this.form.goods_name
      },
      set(val) {
        this.form.goods_name = val
        this.form.goods_bn = ''
        let that = this
        let a = pinyin(val, { toneType: 'none' })
        let b = a.split(' ')
        let arr = []
        b.forEach(el => {
          arr.push(el.slice(0, 1))
        })
        arr = arr.join('')
        this.form.goods_bn = arr
      },
    },
  }
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 18px 13px;
  flex: 1;
  position: relative;
  .addleft {
    margin-right: 18px;
    width: 56px;
    height: 300px;
    z-index: 10;
    position: fixed;
    .onetxt {
      width: 16px;
      height: 16px;
      background: @stepDbgColor;
      border-radius: 16px;
      text-align: center;
      line-height: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      font-family: Helvetica;
      color: #ffffff;
      margin-left: 33px;
    }
    .active {
      background: @stepAbgColor;
    }
    .base {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: @stepDfontColor;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .active1 {
      color: @stepAfontColor;
      font-size: 14px;
      font-weight: 600;
    }
    .line {
      width: 1.5px;
      height: 32px;
      background: @steplineColor;
      margin: 0 5px 6px 40px;
    }
  }
  .addright {
    margin-left: 66px;
    padding-bottom: 20px;
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
    overflow: auto;
    /deep/.ant-col-4 {
      width: 136px !important;
    }
    .zengpinstep {
      padding: 31px 0 26px 28px;
      background: #fff;
      overflow: hidden;
    }
    .zengpinstep2 {
      padding-bottom: 40px;
    }
    .twoh1 {
      display: flex;
      margin-bottom: 20px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 2px;
        margin: 4px 8px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 19px;
      }
    }
    .twoh2 {
      display: flex;
      margin-bottom: 10px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 2px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: @fontColor1;
        line-height: 25px;
      }
    }
    .twoh3 {
      display: flex;
      margin-bottom: 12px;
      .tpointer {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: @primaryColor;
        border-radius: 2px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 20px;
      }
    }
    .one {
      height: calc(100vh - 156px);
    }
    .two {
      height: calc(100vh - 156px);
      overflow-y: auto;
      padding-bottom: 60px;
    }
    .three {
      height: calc(100vh - 156px);
    }
    .four {
      height: 100%;
    }
    .textColor1 {
      display: inline-block;
      width: 136px;
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @fontColor3;
      line-height: 22px;
      // margin-bottom: 14px;
      .xing {
        color: #f35e67;
        font-weight: 500;
      }
    }
    .textSmall {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @primaryColor;
      line-height: 26px;
      height: 26px;
      background: @gray-border-background;
      border-radius: 4px;
      opacity: 0.65;
      padding-left: 8px;
      margin-top: 13px;
    }
    .a-center {
      display: flex;
      align-items: flex-start;
    }
    .three-btns {
      padding-left: 50px;
      padding-bottom: 20px;
      margin-left: 2px;
      // position: fixed;
      // bottom: 23px;
      background: #ffffff;
      // padding-top: 20px;
      width: 83%;
      z-index: 10;
      // border-top: 1px solid @dropdownBs5a;
      border-radius: 0 0 4px 4px;
    }
    .textSmalls {
      font-size: 15px;
      font-weight: 400;
      color: #819190;
    }
  }
  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}

.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 68px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 32px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 188px;
      text-align: left;
      display: inline-block;
    }
    .tabelHeader_items-278 {
      width: 278px;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-bottom: 1px solid #e6eded;
    .tabelBody_items {
      width: 188px;
      text-align: left;
    }
    .tabelBody_items-278 {
      width: 278px;
    }
  }
}
.two {
  /deep/ .avatar-uploader,
  /deep/ .avatar-uploader .ant-upload {
    width: 48px !important;
    height: 48px !important;
    // padding-top: 4px;
  }
}

/deep/.two .avatar-uploader {
  width: 48px !important;
  height: 48px !important;
  padding-top: 2px;
  margin: 0 !important;
}
/deep/.two .ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11 !important;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    flex-wrap: nowrap;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: @fontColor4;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 66px !important;
  height: 66px !important;
  // border: 1px solid #d9d9d9;
  background: #fff;
}

.boldimgspan {
  position: relative;
  .meiye-close-boldimg {
    position: absolute;
    top: -21px;
    right: -8px;
    font-size: 12px;
  }
}
.addBox .add .mgb-32 {
  margin-bottom: 32px;
}

// 多规格样式修改
/deep/.guigeTablebox .ant-table .ant-table-body .datestyle {
  text-align: center;
  border-right: 1px solid @listbodylineColor !important;
}

/deep/.guigeTablebox .ant-table .pdl-24 {
  padding-left: 6% !important;
}
/deep/.guigeTablebox .ant-table .ant-table-thead .pdl-24 {
  padding-left: 6% !important;
}
/deep/.selectGg .ant-select-dropdown-menu {
  padding-bottom: 0 in !important;
}
/deep/.mgb-18.ant-form-item {
  margin-bottom: 18px;
}
.wid-132 {
  width: 132px;
}
/deep/.samerow .ant-cascader-picker-label {
  // display: none;
  box-shadow: none;
}
.pladd {
  color: rgba(45, 56, 53, 0.4);
}
.add .addright .last-btns-step2 {
  height: 68px;
  width: calc(100vw - 291px);
  position: fixed;
  bottom: 19px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 2;
  padding-left: 40px;
}
.mgl-28 {
  margin-left: 28px;
}
.chooseSku {
  display: block;
}
.mgr-20 {
  margin-right: 40px;
}
.guigeImg .mgr-20 {
  margin-right: 40px !important;
}
@media screen and(min-width:1680px) {
  .guigeImg .mgr-20 {
    margin-right: 68px !important;
  }
  .two .mgr-20 {
    margin-right: 68px;
  }
}
 
</style>